
import {css} from '@emotion/react';
import { Colors } from '@vc-workspace/utils-colors';
import { DIMENSIONS } from '@vc-workspace/utils-dimens';

export const containerStyles = css({
  display: 'flex',
  height: '100vh',
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  background: Colors.lavender,
  '@media screen and (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: '100px'
  }
});

export const imgStyles = css({
  width: '420px',
  '@media screen and (max-width: 768px)': {
    width: '100%',
    marginBottom: DIMENSIONS.px_52
  }
})

export const homepageCTA = css({
  display: 'inline-block',
  width: '100%',
  columnGap: DIMENSIONS.px_8,
  marginTop: DIMENSIONS.px_32,
  borderRadius: DIMENSIONS.px_8,
  background: Colors.purple_heart,
  border: `1px solid ${Colors.purple_heart}`,
  padding: DIMENSIONS.px_16,
  fontSize: '14px',
  color: Colors.white,
  '@media screen and (max-width: 768px)': {
    padding: DIMENSIONS.px_12,
    width: '90%'
  }
});