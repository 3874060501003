/** @jsxImportSource @emotion/react */

import Link from 'next/link';
import { Typography } from '@vc-workspace/utils-typography';
import { containerStyles, imgStyles, homepageCTA } from './not-found.module.style';

/* eslint-disable-next-line */
//export interface NotFoundProps {}

export function NotFound({
  headline = `We are unable to find what you're looking for.`,
  subtext = 'Visit home page to explore our products.',
  cta = 'Explore Vibecity',
}) {
  return (
    <div css={containerStyles}>
      <div>
        <img src="https://www.vibecity.com/_mp-images/404_error.png" css={imgStyles} alt="" />
      </div>
      <div className="text-center">
        <Typography type="Headline_H1" color="heavy_metal">
          {headline}
        </Typography>
        <Typography type="Headline_H2" color="heavy_metal" className="pt-2">
          {subtext}
        </Typography>
        <a href="/">
          <button css={homepageCTA}>{cta}</button>
        </a>
      </div>
    </div>
  );
}

export default NotFound;
